<script>
import Form from '../../../../../../../components/form';

export default {
  extends: Form,
  props: {
    formdata: {
      type: Object,
      default: () => ({
        fieldCode: '',
        nafieldNameme: '',
        fieldType: '数据字典',
        fieldDataSource: '',
        fieldKeywords: '',
        compareType: '',
        controlFlag: true,
      }),
    },
  },
  data() {
    return {
      rule: [],
      buttons: {
        submit: false,
        close: false,
        loading: false,
        submitAudit: false,
      },
    };
  },
  created() {
    this.rule = [{
      type: 'input',
      field: 'fieldCode',
      title: '字段编码',
      value: this.formdata.fieldCode,
      props: {
        clearable: true,
      },
      col: {
        md: {
          span: 8,
        },
      },
    },
    {
      type: 'input',
      field: 'fieldName',
      title: '字段名称',
      value: this.formdata.fieldName,
      props: {
        clearable: true,
      },
      col: {
        md: {
          span: 8,
        },
      },
    },
    {
      type: 'input',
      field: 'fieldType',
      title: '字段类型',
      value: this.formdata.fieldType,
      props: {
        disabled: true,
        clearable: true,
      },
      col: {
        md: {
          span: 8,
        },
      },
    },
    {
      type: 'input',
      field: 'fieldDataSource',
      title: '数据字典',
      value: this.formdata.fieldDataSource,
      props: {
        clearable: true,
      },
      on: {
        input: (e) => this.dictChange(e),
      },
      col: {
        md: {
          span: 8,
        },
      },
    },
    {
      type: 'select',
      field: 'fieldKeywords',
      title: '数据字典值',
      // value: this.formdata.fieldKeywords,
      dictCode: this.formdata.fieldDataSource,
      props: {
        multiple: true,
        clearable: true,
      },
      col: {
        md: {
          span: 8,
        },
      },
    },
    {
      type: 'select',
      field: 'compareType',
      title: '比较类型',
      dictCode: 'mdm_approval_field_compare_type',
      value: this.formdata.compareType,
      props: {
        clearable: true,
      },
      col: {
        md: {
          span: 8,
        },
      },
    },
    {
      type: 'switch',
      field: 'controlFlag',
      title: '启用状态',
      value: this.formdata.controlFlag,
      col: {
        md: {
          span: 8,
        },
      },
    }];
  },
  mounted() {
    this.setValue({
      fieldKeywords: this.formdata.fieldKeywords,
    });
  },
  methods: {
    dictChange(e) {
      const dictValue = this.getRule('fieldKeywords');
      dictValue.dictCode = e;
      this.setValue({
        fieldKeywords: [],
      });
      this.reload();
    },
  },
};
</script>

<style>

</style>
