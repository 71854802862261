var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "title-container" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("提交人判断条件")]),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.addFormItem } },
          [_vm._v(" 添加一组 ")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "form-container" },
      _vm._l(_vm.approvalData, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "form-item" },
          [
            _c("el-button", {
              staticClass: "del-btn",
              attrs: { icon: "el-icon-delete", circle: "" },
              on: {
                click: function ($event) {
                  return _vm.delFormItem(index)
                },
              },
            }),
            _c("MatchObjectItem", {
              ref: "form" + index,
              refInFor: true,
              staticStyle: { width: "100%" },
              attrs: { formdata: item },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }