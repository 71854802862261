<template>
  <div class="container">
    <div class="title-container">
      <div class="title">提交人判断条件</div>
      <!-- <el-dropdown @command="handleCommand"> -->
        <el-button type="primary" @click="addFormItem">
          添加一组
        </el-button>
        <!-- <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item,index) in fieldType" :key="index" :command="item.dictCode">{{item.dictValue}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
    <div class="form-container">
      <div class="form-item" v-for="(item,index) in approvalData" :key="index">
        <el-button @click="delFormItem(index)" class="del-btn" icon="el-icon-delete" circle></el-button>
       <MatchObjectItem style="width: 100%" :ref="'form' + index" :formdata = 'item'/>
       <!-- <CustomForm :ref="'form' + index" v-if="item.type==='custom'" :formdata = 'item' /> -->
      </div>
    </div>
  </div>
</template>

<script>
import MatchObjectItem from './match_object_item.vue';
// import CustomForm from './custom_form.vue';
import request from '../../../../../../../utils/request';

export default {
  components: {
    MatchObjectItem,
  },
  props: {
    value: Array,
  },
  data() {
    return {
      approvalData: [],
      matchItem: {
        submitterType: '', // 匹配对象
        fieldKeywords: '', // 关键值
        compareType: '', // 比较类型
        controlFlag: true, // 启用状态
      },
    };
  },
  watch: {
    value(val) {
      this.approvalData = val;
    },
  },
  created() {
    this.getfieldType();
  },
  methods: {
    getFormData() {
      const data = [];
      this.approvalData.forEach((item, index) => {
        if (this.$refs[`form${index}`]) {
          data.push(this.$refs[`form${index}`][0].getFormData());
        }
      });
      return data;
    },
    /**
     * 获取数据类型数据字典
     */
    getfieldType() {
      request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'mdm_approval_field_type',
      }).then((res) => {
        if (res.success) {
          this.fieldType = res.result;
        }
      });
    },
    /**
     * 添加一行数据
     */
    addFormItem() {
      this.approvalData.push(this.matchItem);
    },
    /**
     * 删除一行数据
     */
    delFormItem(index) {
      this.approvalData.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  .title-container {
    display: flex;
    align-items: center;
    .title {

      font-size: 16px;
    height: 20px;
    line-height: 20px;
    font-weight: 600;
      margin-right: 10px;
    }
  }
  .form-container{
    margin-top: 20px;
    .form-item{
      display: flex;
      align-items: center;
    }
  }
  .del-btn{
    width: 30px;
    height: 30px;
  }
}
</style>
