var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "title-container" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("业务数据判断条件")]),
        _c(
          "el-dropdown",
          { on: { command: _vm.handleCommand } },
          [
            _c("el-button", { attrs: { type: "primary" } }, [
              _vm._v(" 添加一组"),
              _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
            ]),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              _vm._l(_vm.fieldType, function (item, index) {
                return _c(
                  "el-dropdown-item",
                  { key: index, attrs: { command: item.dictCode } },
                  [_vm._v(_vm._s(item.dictValue))]
                )
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "form-container" },
      _vm._l(_vm.approvalData, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "form-item" },
          [
            _c("el-button", {
              staticClass: "del-btn",
              attrs: { icon: "el-icon-delete", circle: "" },
              on: {
                click: function ($event) {
                  return _vm.delFormItem(index)
                },
              },
            }),
            item.type === "dict"
              ? _c("DictForm", {
                  ref: "form" + index,
                  refInFor: true,
                  attrs: { formdata: item },
                })
              : _vm._e(),
            item.type === "custom"
              ? _c("CustomForm", {
                  ref: "form" + index,
                  refInFor: true,
                  attrs: { formdata: item },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }