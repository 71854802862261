<script>
import Form from '../../../../../../../components/form';

export default {
  extends: Form,
  props: {
    formdata: {
      type: Object,
      default: () => ({
        submitterType: '', // 匹配对象
        fieldKeywords: '', // 关键值
        compareType: '', // 比较类型
        controlFlag: true, // 启用状态
      }),
    },
  },
  data() {
    return {
      rule: [],
      buttons: {
        submit: false,
        close: false,
        loading: false,
        submitAudit: false,
      },
    };
  },
  created() {
    this.rule = [{
      type: 'select',
      field: 'submitterType',
      title: '匹配对象',
      value: this.formdata.submitterType,
      dictCode: 'mdm_approval_submitter_type',
      props: {
        clearable: true,
        loading: false,
      },
      on: {
        change: (e) => this.submitterTypeChange(e),
      },
      col: {
        md: {
          span: 8,
        },
      },
    },
    // {
    //   type: 'select',
    //   field: 'fieldKeywords',
    //   title: '关键值',
    //   value: this.formdata.fieldKeywords,
    //   props: {
    //     clearable: true,
    //     loading: false,
    //   },
    //   col: {
    //     md: {
    //       span: 8,
    //     },
    //   },
    // },
    {
      type: 'select',
      field: 'compareType',
      title: '字段类型',
      value: this.formdata.compareType,
      dictCode: 'mdm_approval_submitter_compare_type',
      props: {
        clearable: true,
        loading: false,
      },
      col: {
        md: {
          span: 8,
        },
      },
    },
    {
      type: 'switch',
      field: 'controlFlag',
      title: '启用状态',
      value: this.formdata.controlFlag,
      col: {
        md: {
          span: 8,
        },
      },
    }];
  },
  mounted() {
    this.submitterTypeChange(this.formdata.submitterType);
  },
  methods: {
    /**
     * 匹配对象Change事件
     */
    submitterTypeChange(e) {
      this.fApi.removeField('fieldKeywords');
      setTimeout(() => {
        if (e === 'role') {
          this.append([{
            type: 'select',
            field: 'fieldKeywords',
            title: '关键值',
            value: this.formdata.fieldKeywords,
            restful: '/mdm/mdmRoleController/roleSelectList',
            optionsKey: { label: 'unionName', value: 'roleCode' },
            props: {
              clearable: true,
              loading: false,
              filterable: true,
              remote: true,
              multiple: true,
              remoteParams: 'unionName',
            },
            col: {
              md: {
                span: 8,
              },
            },
          }], 'submitterType');
        }
        if (e === 'position') {
          this.append([{
            type: 'select',
            field: 'fieldKeywords',
            title: '关键值',
            value: this.formdata.fieldKeywords,
            restful: '/mdm/mdmPositionController/positionSelectList',
            optionsKey: { label: 'unionName', value: 'positionCode' },
            props: {
              clearable: true,
              loading: false,
              filterable: true,
              remote: true,
              multiple: true,
              remoteParams: 'unionName',
            },
            col: {
              md: {
                span: 8,
              },
            },
          }], 'submitterType');
        }
        if (e === 'org') {
          this.append([{
            type: 'FormSelectTreeNew',
            field: 'fieldKeywords',
            title: '关键值',
            value: this.formdata.fieldKeywords,
            props: {
              restful: '/mdm/baseTreeController/orgTree',
              clearable: true,
              loading: false,
              multiple: true,
              props: {
                parent: 'parentCode', // 父级唯一标识
                value: 'code', // 唯一标识
                label: 'name', // 标签显示
                children: 'children', // 子级
              },
            },
            col: {
              md: {
                span: 8,
              },
            },
          }], 'submitterType');
        }
      }, 100);
    },
  },
};
</script>

<style>

</style>
